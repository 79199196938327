@import 'mapbox-gl/dist/mapbox-gl.css';

@font-face {
    font-family: 'AimsunLight';
    font-style: normal;
    src: local('AimsunLight'), url('../public/fonts/RegularAimsun-Light.otf') format('opentype');
}

@font-face {
    font-family: 'AimsunMedium';
    font-style: normal;
    src: local('AimsunMedium'), url('../public/fonts/RegularAimsun-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'AimsunBold';
    font-style: normal;
    font-weight: bold;
    src: local('AimsunBold'), url('../public/fonts/RegularAimsun-Bold.otf') format('opentype');
}

body {
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.charts__tooltip-container {
    padding: 16px;
    font-family: 'AimsunMedium';
}
.charts__tooltip-container span {
    font-family: 'AimsunBold';
}

.charts__tooltip-content--highlight-red {
    font-family: 'AimsunMedium';
    font-weight: bold;
    color: #f04c29;
    margin-right: 4px;
}
.charts__tooltip-content--highlight {
    font-family: 'AimsunMedium';
    font-weight: bold;
    color: #09254c;
    margin-right: 4px;
}

.charts__tooltip-content--highlight-green {
    font-family: 'AimsunMedium';
    color: #009186;
    margin-right: 4px;
    font-weight: bold;
}

.qm__tooltip {
    width: fit-content;
    height: fit-content;
    padding: 5px;
    background-color: #fff;
    border-radius: 3px;
}

.qm__tooltip-text {
    color: #09254c;
    margin: 15px;
}

.qm__tooltip-text .qm__tooltip-text--grey {
    color: grey;
}

.mapboxgl-ctrl-logo {
    display: none !important;
}

.apexcharts-tooltip,
.apexcharts-tooltip.apexcharts-active,
.apexcharts-xaxistooltip,
.apexcharts-xaxistooltip.apexcharts-active,
.apexcharts-marker {
    transition: none;
}
