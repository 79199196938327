@font-face {
    font-family: 'AimsunLight';
    font-style: normal;
    src: url('../public/fonts/RegularAimsun-Light.otf') format('otf');
}

@font-face {
    font-family: 'AimsunMedium';
    font-style: normal;
    src: url('../public/fonts/RegularAimsun-Medium.otf') format('otf');
}

@font-face {
    font-family: 'AimsunBold';
    font-style: bold;
    src: url('../public/fonts/RegularAimsun-Bold.otf') format('otf');
}

.mapboxgl-popup-content {
    padding: 20px 20px 5px;
}
